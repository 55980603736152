<template>
  <edit-template type="card" @confirm="handleSubmit" :confirmLoading="loading" v-loading="loading">
    <div class="z-box-bg top-info">
      <div class="top-info-item" v-for="item in list" :key="item.label">
        <div class="top-info-label">{{ item.label }}</div>
        <div class="top-info-value">{{ item.value }}</div>
      </div>
    </div>
    <div class="p-4">
      <DynamicForm ref="dynamicDom" :list="formConfig" labelWidth="165rem"></DynamicForm>
    </div>
  </edit-template>
</template>

<script>
import DynamicForm from "@/components/common/DynamicForm"
import { changeOption, setConfigOption, setConfigValue } from "@/utils"
import { zsPost, zsGet, get_2024government } from '@/api'
export default {
	_config:{"route":{"path":"edit2","meta":{"title":"编辑"}}},
  components: { DynamicForm },
  data() {
    return {
      loading: false,
      searchLoading: false,
      student_status: '',
      list: [],
      formConfig: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    remoteMethod(query){
      if(!query) return
      this.searchLoading = true
      zsGet('/recruit/common/junior-high-school', { name: query }).then(res => {
        setConfigOption({ list: this.formConfig, data: res, key: 'student_attend_graduation_school', label: 'name', value: 'id' })
      }).finally(() => {
        this.searchLoading = false
      })
    },
    getArea(key, parent_code = '000000') {
      get_2024government({ parent_code }).then(res => {
        setConfigOption({ list: this.formConfig, data: res, key, label: 'address_name', value: 'address_code' })
      })
    },
    getData() {
      this.loading = true
      zsGet('/recruit/fresh-graduate/show-v2', { order_id: this.$route.query.id }).then(res => {
        this.list = [
          { label: '学生姓名', value: res.student_name },
          { label: '性别', value: res.sex },
          { label: '家长姓名', value: res.parent_name },
          { label: '家长和学生的关系', value: res.relationship_name },
        ]
        const study_school_update_status = res.study_school_update_status
        const student_status = res.student_status === -1 ? '' : res.student_status
        this.student_status = student_status || ''
        const score_image = res.score_image ? [{ src: res.score_image }] : []
        const receiver_province = res.receiver_province || ''
        const receiver_city = res.receiver_city || ''
        const receiver_area = res.receiver_area || ''

        this.formConfig = [
          { label: '正式报名实际就读学校', prop: 'study_school_id', tag: 'select', value: res.study_school_id, span: 8, options: [], custom: { disabled: study_school_update_status === 1 } },
          { label: '学籍所属学校', prop: 'belong_school_id', tag: 'select', value: res.belong_school_id, span: 16, options: [] },

          { label: '居住寝室', prop: 'dormitory_type', tag: 'select', value: res.dormitory_type, span: 8, options: [{ label: '4人间', value: '4人间' }, { label: '6人间', value: '6人间' }, { label: '8人间', value: '8人间' }] },
          { label: '学生所属业务线', prop: 'business_type', tag: 'select', value: res.business_type, span: 8, options: [{ label: '普通生', value: '普通生' }, { label: '拔尖生', value: '拔尖生' },{ label: '培优生', value: '培优生' }, { label: '校区优生', value: '校区优生' }] },
          { label: '毕业学校', prop: 'student_attend_graduation_school', tag: 'select', value: res.student_attend_graduation_school, placeholder: '请搜索', span: 8, options: [], custom: { filterable: true, remote: true, 'reserve-keyword': true, 'remote-method': this.remoteMethod, loading: this.searchLoading} },

          { label: '家长银行账户', prop: 'parent_bank', maxlength: 30, value: res.parent_bank, span: 8 },
          { label: '开户行', prop: 'bank_name', maxlength: 30, value: res.bank_name, span: 8 },
          { label: '开户人', prop: 'bank_user', maxlength: 16, value: res.bank_user, span: 8 },

          { label: '班主任姓名', prop: 'main_teacher_name', maxlength: 16, value: res.main_teacher_name, span: 8 },
          { label: '班主任联系电话', prop: 'main_teacher_phone', maxlength: 11, value: res.main_teacher_phone, span: 8 },
          { label: '毕业班级', prop: 'student_attend_graduation_class', maxlength: 16, value: res.student_attend_graduation_class, span: 8 },

          { label: '中考准考证号', prop: 'admission_ticket_no', maxlength: 30, value: res.admission_ticket_no, span: 8 },
          { label: '中考报名号', prop: 'application_no', maxlength: 30, value: res.application_no, span: 8 },
          { label: '修改学生状态', prop: 'student_status', span: 8, tag: 'select', value: student_status, span: 8, options: [], hide: true, custom: { disabled: study_school_update_status === 1 } },

          { label: '中考成绩截图', prop: 'score_image', tag: 'upload', value: score_image, span: 24, custom: { limit: 1 } },

          { label: '收件人姓名', prop: 'receiver_name', maxlength: 16, value: res.receiver_name, span: 8 },
          { label: '收件人电话', prop: 'receiver_phone', maxlength: 11, value: res.receiver_phone, span: 8 },

          { label: '收货地址', span: 24, children: [
              {
                prop: 'receiver_province', tag: 'select', value: receiver_province, span: 5, options: [],
                change: (value) => {
                  this.getArea('receiver_city', value)
                  setConfigOption({ list: this.formConfig, key: 'receiver_area' })
                  setConfigValue({ list: this.formConfig, key: ['receiver_province'], value })
                  setConfigValue({ list: this.formConfig, key: ['receiver_city', 'receiver_area'] })
                }
              },
              {
                prop: 'receiver_city', tag: 'select', value: receiver_city, span: 5, options: [],
                change: (value) => {
                  this.getArea('receiver_area', value)
                  setConfigValue({ list: this.formConfig, key: ['receiver_city'], value })
                  setConfigValue({list: this.formConfig,  key:['receiver_area'] })
                }
              },
              { prop: 'receiver_area', tag: 'select', value: receiver_area, span: 5, options: [] }
            ]
          },

          { label: '详细地址', prop: 'receiver_address', maxlength: 50, value: res.receiver_address, type: 'textarea', span: 24, custom: { autosize: { minRows: 1, maxRows: 2 }, showWordLimit: true } },

          { label: '备注信息', prop: 'content', maxlength: 100, isSingle: true, type: 'textarea', span: 24, custom: { autosize: { minRows: 3, maxRows: 4 }, showWordLimit: true } }
        ]

        // 实际就读学校 学籍所属学校
        zsGet('/recruit/common/consulting-school', { order_id: this.$route.query.id }).then(res => {
          const { entity_school = [] } = res
          const list = changeOption({list: entity_school, label: 'school_name', value: 'id'})
          setConfigOption({ list: this.formConfig, data: list, key: 'study_school_id' })
          setConfigOption({ list: this.formConfig, data: list, key: 'belong_school_id' })
        })

        // 获取省市区
        this.getArea('receiver_province')
        res.receiver_province && this.getArea('receiver_city', res.receiver_province)
        res.receiver_city && this.getArea('receiver_area', res.receiver_city)

        // 学生状态
        zsGet('/recruit/fresh-graduate/student-status').then(data => {
          const list = Object.keys(data).map(item => ({ label: data[item], value: Number(item), disabled: true }))
          const changeStatus = (val) => {
            const obj = list.find(item => item.value === val)
            if(!obj) return
            obj.disabled = false
          }
          changeStatus(res.student_status)
          if([1, 2, 6].includes(res.student_status)) {
            changeStatus(4)
          } else if(res.student_status === 4){
            changeStatus(res.fallback_state)
          }
          setConfigOption({ list: this.formConfig, data: list, key: 'student_status' })
          if([0, 1].includes(res.order_status)) {
            setConfigValue({list: this.formConfig,  key: 'student_status', changeKey: 'hide', value: false })
          } else if (res.student_status === 4 && study_school_update_status === 0) {
            setConfigValue({list: this.formConfig,  key: 'student_status', changeKey: 'hide', value: false })
          }
        })

        // 毕业学校
        res.student_attend_graduation_school && zsGet('/recruit/common/junior-high-school', { id: res.student_attend_graduation_school }).then(data => {
          setConfigOption({ list: this.formConfig, data, key: 'student_attend_graduation_school', label: 'name', value: 'id' })
        })
      }).finally(() => this.loading = false)
    },
    handleSubmit() {
      this.$refs.dynamicDom.validate().then((form) => {
        let msg = ''
        if(form.student_status === 4 && this.student_status !== 4) {
          msg = '标记拒绝入学后，此学生将无法分班入学'
        } else if(form.student_status === 2 && this.student_status !== 2) {
          msg = '标记已报名后，此学生可继续入学，请与财务处沟通缴费'
        }
        if(!msg) return this.handleRequest(form)
        this.$confirm(msg, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.handleRequest(form)
        })
      })
    },
    handleRequest(form) {
      const data = { ...form, order_id: this.$route.query.id }
      if(data.score_image.length && (data.score_image[0].src ||!data.score_image[0]?.response?.status)) {
        data.score_image = data.score_image[0].src || data.score_image[0]?.response?.data?.file_addr
      } else {
        data.score_image = ''
      }
      this.loading = true
      zsPost('/recruit/fresh-graduate/update-v2', data).then(() => {
        this.$message.success('操作成功')
        this.$router.go(-1)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.top-info {
  border-bottom: solid 20rem #f7f7f7;
  display: flex;
  &-item {
    margin-right: 130rem;
    font-size: 16rem;
    display: flex;
  }
  &-label {
    margin-right: 30rem;
  }
  &-value {
    font-weight: 600;
  }
}
</style>
